import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import styled from 'styled-components';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';

import MDButton from 'components/material/MDButton';
import MDBox from 'components/material/MDBox';
import BasicSelect from 'components/common/Select';
import { useGetRegionsQuery } from 'api/region';
import { setSearchRegion } from 'store/userSlice';
import { useGetAccessibleCountiesQuery } from 'api/county';
import { setCountySearch } from 'store/userSlice';
import { useGetUsers } from 'hooks/useGetUsers';
import { downloadBuffer } from 'services/downloadFile';
import { setSearchTerm } from 'store/userSlice';

const StyledInput = styled.input`
  width: 220px;
  height: 44px;
  background-color: #f0f2f5;
  border-radius: 8px;
  padding-left: 12px;
  outline: none;
  transition: all 0.4s;
  border: 2px solid transparent;

  &:focus {
    border: 2px solid #1a73e8;
  }
`;

const TbaleHeader = () => {
  const [userFilters, setUserFilters] = useState({ search: '', region: 0, county: 0 });

  const { data: regions, isLoading: regionsLoading } = useGetRegionsQuery();
  const { data: counties, isLoading: countiesLoading } = useGetAccessibleCountiesQuery();
  const { access_token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data } = useGetUsers();

  const downloadFile = () => {
    return axios.post(`${process.env.REACT_APP_API_URL}/user/generate/xlsx`, data, {
      responseType: 'arraybuffer',
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
  };

  const handleDownloadXlsx = async () => {
    const { data } = await downloadFile();
    downloadBuffer(data, 'xlsx');
  };

  const handleNameChange = (e) => {
    setUserFilters({ ...userFilters, search: e.target.value });
  };

  const handleSearchClick = () => {
    const { region, county, search } = userFilters;
    dispatch(setSearchRegion(region));
    dispatch(setSearchTerm(search));
    dispatch(setCountySearch(county));
  };

  const handleClearFilters = () => {
    dispatch(setSearchRegion(0));
    dispatch(setCountySearch(0));
    dispatch(setSearchTerm(''));
  };

  const renderMenu = (
    <>
      <MDButton style={{ marginRight: 16 }} color="success" onClick={handleDownloadXlsx}>
        <FileDownloadIcon style={{ marginRight: 4 }} /> XLSX
      </MDButton>
      <MDButton
        color="primary"
        onClick={() => {
          navigate('/dashboard/create-user');
        }}
      >
        <AddIcon /> Adauga
      </MDButton>
    </>
  );

  return (
    <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
      <MDBox display="flex" alignItems="center">
        {!regionsLoading && regions?.length && (
          <BasicSelect
            label="Regiune"
            style={{ width: 140, background: '#f0f2f5', marginRight: 16 }}
            value={userFilters.region}
            options={[{ name: 'Toate regiunile', id: 0 }, ...regions].map((region) => ({
              key: region.name,
              value: region.id,
            }))}
            handleChange={(region) => {
              setUserFilters({ ...userFilters, region });
            }}
          />
        )}
        {!countiesLoading && counties && (
          <BasicSelect
            label="Judet"
            style={{ width: 140, background: '#f0f2f5', marginRight: 16 }}
            value={userFilters.county}
            options={[{ name: 'Toate judetele', id: 0 }, ...counties].map((county) => ({
              key: county.name,
              value: county.id,
            }))}
            handleChange={(county) => {
              setUserFilters({ ...userFilters, county });
            }}
          />
        )}
        <StyledInput
          name="searchTerm"
          value={userFilters.search}
          placeholder="Cauta dupa nume"
          onChange={handleNameChange}
        />
        <MDButton style={{ marginLeft: 16 }} color="primary" onClick={handleSearchClick}>
          <SearchIcon />
          Cauta
        </MDButton>
        <MDButton variant="text" color="primary" onClick={handleClearFilters}>
          Reseteaza
        </MDButton>
      </MDBox>

      <MDBox style={{ marginLeft: '16px' }}>{renderMenu}</MDBox>
    </MDBox>
  );
};

export default TbaleHeader;
